.downloadChapter{
    padding: var(--spacing-large);

}

@media screen and (max-width: 800px){
    .downloadChapter{
        flex-direction: column;
    }
    .PdfDownloadLink:not(:first-child){
        margin-left: 0 !important;
    }
}

.PdfDownloadLink:not(:first-child){
    margin-left: var(--spacing-medium);
}

.PdfDownloadLink{
    border: solid var(--border-size-medium) var(--secondary-color);
    padding: var(--spacing-large);
    margin: var(--spacing-large) 0;
    color: var(--secondary-color);
    border-radius: var(--border-radius);
    width: 8em;
    text-align: center;
    font-size: 1.2em;
    box-shadow:
            0 0 5px var(--color-shadow-close),
            0 0 10px var(--color-shadow-secondary);

}

.PdfDownloadLink:hover{
    cursor: pointer;
    color: var(--secondary-color-lighter);
    border-color: var(--secondary-color-lighter);
    box-shadow:
            0 0 5px var(--color-shadow-close),
            0 0 10px var(--color-shadow-secondary),
            0 0 20px var(--color-shadow-secondary);
    text-shadow:
            0 0 5px var(--color-shadow-close),
            0 0 10px var(--color-shadow-secondary),
            0 0 20px var(--color-shadow-secondary),
            0 0 30px var(--color-shadow-secondary);
}