
.SideMenu {
    background-color: var(--background-color-header);
    width: 200px;
    display: flex ;
    justify-content: space-between;
    align-items: center;
    padding: var(--spacing-small) 0;
}

@media screen and (max-width: 600px) {
    .SideMenu{
        position: absolute;
        background-color: var(--background-color-header);
        top: 0;
        height: 100vh;
        width: 100%;
        display: flex ;
        justify-content: space-between;
        z-index: 500;
    }

    .fullscreen.SideMenu {
        left: 0;
        animation: appear var(--animation-time-long) ease-out;
    }

    .hidden.SideMenu{
        left: -600px;
        animation: disappear var(--animation-time-long) ease-in;
    }

    .logo-container{
        margin-top: 50px;
    }
}

.menu_object_container{
    height: 400px;
    justify-content: space-around;
    width: 200px;
}

.logo{
    width: 60px;
    height: 60px;
    margin-top: var(--spacing-small);
    border-radius: 6px;
}

.logo:hover{
    box-shadow: 0 0 2px var(--color-shadow-close),
    0 0 5px var(--color-shadow-close),
    0 0 10px var(--color-shadow-primary);
}

.SideMenu > .logo-container{
    size: 3em;
    font-size: xxx-large;
    text-align: center;
    width: 100%;
    cursor: pointer;
    color: var(--secondary-text-color);
}

.menu-object{
    background-color: var(--background-color-menu);
    color: var(--secondary-text-color);
    height: 3vw;
    transition: height var(--animation-time) ease-out, color var(--animation-time) ease-out, border-left-width var(--animation-time) linear, letter-spacing var(--animation-time) ease-out;
    font-size: large;
    /*text-shadow:*/
    /*        0 0 50px var(--secondary-text-color);*/
}

.menu-object:hover{
    /*color: var(--primary-color-hover);*/
    color: var(--secondary-text-color);
    cursor: pointer;
    transition:
            /*text-shadow var(--animation-time) ease-in-out,*/
            letter-spacing var(--animation-time) ease-in-out;
    letter-spacing: 1px;
    text-shadow:
            0 0 5px var(--color-shadow-primary),
            0 0 10px var(--color-shadow-primary),
            0 0 20px var(--color-shadow-primary),
            0 0 40px var(--color-shadow-primary);
}

@-webkit-keyframes glow {
    from {
        text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #e60073, 0 0 40px #e60073, 0 0 50px #e60073, 0 0 60px #e60073, 0 0 70px #e60073;
    }
    to {
        text-shadow: 0 0 20px #fff, 0 0 30px #ff4da6, 0 0 40px #ff4da6, 0 0 50px #ff4da6, 0 0 60px #ff4da6, 0 0 70px #ff4da6, 0 0 80px #ff4da6;
    }
}


.menu-object.hidden{
    height: 0;
    color: transparent;
    transition:
            height var(--animation-time) ease-out,
            color var(--animation-time) ease-out,
            border-left-width var(--animation-time) linear;
}

.menu-object.active{
    color: var(--menu-color-active);
    cursor: default;
    transition:
            color var(--animation-time) ease-in-out,
            letter-spacing var(--animation-time) ease-in-out;
}

.menu-object.active:hover{
    color: var(--secondary-text-color);
}
