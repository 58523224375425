/* flex box */
.flex {
    display: flex;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-start {
    justify-content: flex-start;
    align-items: center;
}

.flex-center {
    justify-content: center;
    align-items: center;
}

.justify-content-center{
    justify-content: center;
}
.justify-content-end{
    justify-content: end;
}

.align-items-center{
    align-items: center;
}

.flex-end {
    justify-content: flex-end;
    align-items: flex-end;
}

.flex-wrap {
    flex-wrap: wrap;
}

.flex-grow {
    flex-grow: 1;
}

.flex-shrink {
    flex-shrink: 1;
}

.justify-self-center {
    justify-self: center;
}

.justify-self-end {}

.content-space-around {
    justify-content: space-around;
}
.content-space-between {
    justify-content: space-between;
}

.grid{
    display: grid;
}

/*Size*/

.width-fluid {
    width: 100%;
}

.height-fluid {
    height: 100%;
}

.fluid {
    width: 100%;
    height: 100%;
}

.w100{
    width: 100%
}
