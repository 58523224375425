.offer_chapter{
}

.offer_chapter > div:not(:first-child) {
    padding-top: var(--spacing-tiny);
}

.offer_chapter > div > h3{
    transition: letter-spacing var(--animation-time) ease-in-out, color var(--animation-time) ease-in-out, text-shadow var(--animation-time) ease-in-out;
    /*transition-delay: 0.1s;*/
    padding-bottom: var(--spacing-tiny);
    padding-top: var(--spacing-tiny);
}

.offer_chapter > div > p{
    padding-left: var(--spacing-tiny);
}

.offer_chapter > div > h3:hover{
    color: var(--secondary-color);
    text-shadow: 0 0 5px var(--color-shadow-close),
    0 0 10px var(--color-shadow-secondary),
    0 0 20px var(--color-shadow-secondary),
    0 0 30px var(--color-shadow-secondary);
    letter-spacing: 1px;
}