.impressum {
    text-align: left;
}

.impressum-body {
    display: flex;
    margin: 0 var(--spacing-large);
}

@media screen and (max-width: 1200px) {
    .impressum-body {
        flex-direction: column;
    }
}

.impressum > h1 {
    font-size: 3em;
    margin: var(--spacing-large) 0;
    color: var(--primary-color);
}

.impressum-text > h2 {
    margin-top: var(--spacing-medium);
    margin-bottom: var(--spacing-small);
    color: var(--secondary-color);
}

.impressum-text > p {
    margin-left: var(--spacing-medium);
    text-align: left;
    word-wrap: break-word;
}

@media screen and (max-width: 600px){
    .impressum-text > p {
        margin-left: var(--spacing-small);
    }
    .impressum-body{
        margin: 0 var(--spacing-small);
    }
}

