.project-card{
    position: relative;
    width: 100%;
    border-radius: 50px;

}

@media screen and (max-width: 1200px) {
    .project-row {
        flex-direction: column;
    }
}

@media screen and (max-width: 600px) {
    .project-row {
        flex-direction: column;
    }

    .skill-list{
        flex-direction: column;
    }

    .project-text{
        margin-left: var(--spacing-small);
    }
}

.project-subtitle{
    width: 12em;
    color: var(--secondary-color);
}

.project-icons {
    margin-bottom: var(--spacing-medium);
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(50px, 1fr));
    grid-column-gap: 6px;
    grid-row-gap:6px;
    max-width: 80%;
}

.project-skill-logo{
    width:50px;
    height: 50px;
    /*background-color: rgba(255,255,255,0.8);*/
    background-color: rgba(255,255,255,0.9);
    /*border: solid var(--primary-color);*/
    border-radius: 25px;

}

.project-skill-logo:first-child{
    margin-left: var(--spacing-small);
}

/* Timeline Container */
.timeline {
    /*padding: 20px;*/
    padding-left: 2px;
    width: 95%
}

/* setting padding based on even or odd */
.project-card:nth-child(odd) {
    padding: var(--spacing-timeline) 0 var(--spacing-timeline) var(--spacing-timeline);
}
.project-card:nth-child(even) {
    padding: var(--spacing-timeline) var(--spacing-timeline) var(--spacing-timeline) 0;
}
/* Global ::before */
.project-card::before {
    content: "";
    position: absolute;
    width: 50%;
    border: solid var(--primary-color);
}

/* Setting the border of top, bottom, left */
.project-card:nth-child(odd)::before {
    left: 0;
    top: -4.5px;
    bottom: -4.5px;
    border-width: 5px 0 5px 5px;
    border-radius: 50px 0 0 50px;
}

/* Setting the top and bottom to "-5px" because earlier it was out of a pixel in mobile devices */
@media only screen and (max-width: 400px) {
    .project-card:nth-child(odd)::before {
        top: -4.5px;
        bottom: -5px;
    }
}

/* Setting the border of top, bottom, right */
.project-card:nth-child(even)::before {
    right: 0;
    top: 0;
    bottom: 0;
    border-width: 5px 5px 5px 0;
    border-radius: 0 50px 50px 0;
}

/* Removing the border if it is the first card */
.project-card:first-child::before {
    border-top: 0;
    border-top-left-radius: 0;
}

/* Removing the border if it is the last card  and it's odd */
.project-card:last-child:nth-child(odd)::before {
    border-bottom: 0;
    border-bottom-left-radius: 0;
}

/* Removing the border if it is the last card  and it's even */
.project-card:last-child:nth-child(even)::before {
    border-bottom: 0;
    border-bottom-right-radius: 0;
}

/* text right if the card is even  */
.project-card:nth-child(even) {
    /*padding-left: 50px;*/
}

.project-card:nth-child(even) > .project-row {
    padding-left: 50px;
}

.project-card:nth-child(even) > .project-icons {
    padding-left: 40px;
}

/*.project-icons::before{*/
/*    content: "";*/
/*    position: absolute;*/
/*    width: 15px;*/
/*    height: 15px;*/
/*    background: white;*/
/*    border-radius: 999px;*/
/*    border: 3px solid var(--primary-color);*/
/*}*/

/*!* setting dot to the left if the card is odd *!*/
/*.project-card:nth-child(odd) > .project-icons::before {*/
/*    left: -7px;*/
/*    top:20px;*/
/*}*/

/*!* setting dot to the right if the card is odd *!*/
/*.project-card:nth-child(even) > .project-icons::before {*/
/*    right: -7px;*/
/*    top:20px;*/
/*}*/
