h1 {
    color: var(--primary-color);
}

h2 {
    color: var(--primary-color);
}

h3 {
    color: var(--text-color-h3);
}

p {
    color: var(--text-color-p);
    hyphens: auto;
    text-align: justify
}

#top {
}

.mainContent {
    overflow-y: auto;
    overflow-x: hidden;
    -ms-overflow-style: none; /* IE and Edge */
    /*scrollbar-width: none;  !* Firefox *!*/
}

.mainContent::-webkit-scrollbar {
    display: none;
}

.overlay {
    background-color: var(--overlay-color);
    opacity: 0.8;
    transition: background-color var(--animation-time-long) ease-out;
}

.spacer{
    padding: var(--spacing-large);
}

@media screen and (max-width: 600px){
    .spacer{
        padding-top: 60px;
    }

}
@media screen and (max-width: 575px){
    .spacer{
        padding: var(--spacing-tiny);
        padding-top: 60px;
    }

}


.programming-keyword {
    color: var(--color-dark-orange);
}

