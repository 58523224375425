.chapter-content > p {
    font-size: 1.1em;
}

.chapter-content > p:not(:first-child) {
    padding-top: var(--spacing-small);
}

@media screen and (max-width: 400px) {
    .chapter-content > p {
        font-size: 1em;
    }
}