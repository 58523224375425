
@media screen and (max-width: 1200px) {
    .top {
        flex-direction: column;
        margin: 0;
    }


    .main-picture {
        position: relative;
        /*left:-26px;*/
        align-self: center;
        margin: 0 0 var(--spacing-medium) 0 !important;
        padding: 0 !important;
    }
}

@media screen and (max-width: 900px) {
    .top {
        flex-direction: column;
    }

    .mainTitle {
        font-size: 3em !important;
    }

    .slogan {
        font-size: 1.5em !important;
    }
}

@media screen and (max-width: 700px) {
    .slogan {
        font-size: 1.0em !important;
    }
}

@media screen and (max-width: 600px) {
    .slogan {
        font-size: 0.95em !important;
    }
}

@media screen and (max-width: 350px) {
    .slogan {
        font-size: 0.75em !important;
    }

    .mainTitle {
        font-size: 2.5em !important;
    }
}

@media screen and (max-width: 300px) {
    .slogan {
        font-size: 0.7em !important;
    }
}

.main-picture {
    object-fit: cover;
    max-width: 20em;
    border-radius: 2em;
    border: solid var(--color-shadow-primary) 1px;
    box-shadow: 0 0 2px var(--color-shadow-primary),
    0 0 5px var(--color-shadow-primary),
    0 0 10px var(--color-shadow-primary),
    0 0 20px var(--color-shadow-primary);
    margin-left: var(--spacing-large);
}

.mainTitle {
    font-size: 4.5em;
}

.title {
    font-size: var(--font-size-medium);
    padding-top: var(--spacing-small);
}


.mail {
    font-size: var(--font-size-medium);
    color: var(--secondary-color);
    transition: text-shadow var(--animation-time) ease-in-out,
    letter-spacing var(--animation-time) ease-in-out;
    text-shadow: 0 0 5px var(--color-shadow-close),
    0 0 10px var(--color-shadow-secondary),
    0 0 20px var(--color-shadow-secondary);
}

.telefonNumber {
    font-size: var(--font-size-medium);
}

.mail:hover {
    /*color: var(--secondary-color-darker);*/
    color: var(--secondary-color-lighter);
    cursor: pointer;
    letter-spacing: 1px;
    transition: letter-spacing var(--animation-time) ease-in-out;
    text-shadow: 0 0 5px var(--secondary-color-lighter),
    0 0 10px var(--secondary-color-lighter),
    0 0 20px var(--color-shadow-secondary),
    0 0 40px var(--color-shadow-secondary),
    0 0 80px var(--color-shadow-secondary);
}

.slogan {
    font-size: var(--font-size-large);
    color: var(--secondary-color);
    overflow: hidden;
    overflow-wrap: break-word;
    white-space: nowrap;
    letter-spacing: 0.02em;
    /*border-right: 0.15em solid var(--secondary-color);*/
    /*animation: typing 35s steps(60, end) infinite, cursor-blink 1s step-end infinite;*/
    padding: 1px;
    text-shadow: 0 0 5px var(--color-shadow-close),
    0 0 10px var(--color-shadow-secondary),
    0 0 20px var(--color-shadow-secondary),
    0 0 30px var(--color-shadow-secondary);
    /*-webkit-animation: glow 1s ease-in-out infinite alternate;*/
    /*-moz-animation: glow 1s ease-in-out infinite alternate;*/
    /*animation: glow 1s ease-in-out infinite alternate;*/
}

@-webkit-keyframes glow {
    from {
        text-shadow: 0 0 5px var(--color-shadow-close),
        0 0 10px var(--color-shadow-secondary),
        0 0 20px var(--color-shadow-secondary);
    }
    to {
        text-shadow: 0 0 5px var(--color-shadow-close),
        0 0 10px var(--color-shadow-secondary),
        0 0 20px var(--color-shadow-secondary),
        0 0 30px var(--color-shadow-secondary),
        0 0 50px var(--color-shadow-secondary);
    }
}

.part1 {
    animation: typing 35s steps(30, end) infinite;
}

.part2 {
    animation: typing-part2 35s steps(30, end) infinite;
}

@keyframes typing {
    0% {
        width: 0;
    }
    5% {
        width: 100%
    }
}

@keyframes typing-part2 {
    0% {
        width: 0;
    }
    5% {
        width: 0;
    }
    10% {
        width: 100%
    }
}

@keyframes cursor-blink {
    from,
    to {
        border-color: transparent;
    }
    50% {
        border-color: var(--secondary-color);
    }
}