.skills{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    grid-column-gap: 6px;
    grid-row-gap:6px;
    justify-items: left;
}

.skills > div {
    margin-bottom: var(--spacing-medium);
}

.skill-row{
    margin-left: var(--spacing-large);
}

.skill-name {
    width: 7em;
    height: 50px;
}

@media screen and (max-width: 575px){
    .skill-row{
        margin-left: var(--spacing-tiny);
    }

    div[class^=circle] {
        height: 15px;
        width: 15px;
    }
}
@media screen and (max-width: 310px){
    .skill-row{
        margin-left: 0;
    }

    div[class^=circle] {
        height: 12px;
        width: 12px;
    }
}
@media screen and (max-width: 280px){
    div[class^=circle] {
        height: 10px;
        width: 10px;
    }
}

