#top_menu {
    position: fixed;
    right: 0;
    top: 0;
    visibility: hidden;
    background-color: var(--overlay-color);
    z-index: 1000;
    width: 100vw;
}

@media screen and (max-width: 600px) {
    #top_menu {
        visibility: visible;
    }
}

#top_menu > .logo-container{
    font-size: xxx-large;
    text-align: center;
    cursor: pointer;
    color: var(--secondary-text-color);
    margin: 0 var(--spacing-tiny);
    padding: 0;
    height: 48px;

}

#top_menu > .logo-container > .logo {
    height: 0.7em;
    width: 0.7em;
    margin: 0;
}