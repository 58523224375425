.chapter{

}

.chapter >.title {
    font-size: 2em;
    margin: var(--spacing-large) 0;
    color: var(--primary-color);
    text-shadow:
            0 0 5px var(--color-shadow-close),
            0 0 10px var(--color-shadow-close),
            0 0 15px var(--color-shadow-close),
            0 0 20px var(--color-shadow-primary);
}

.subchapter > .title {
    font-size: 2em;
    margin: var(--spacing-medium);
    color: var(--secondary-color);
}

.chapter-content{
    border-radius: var(--border-radius-large);
    padding: var(--spacing-medium);
    transition: background-color var(--animation-time-short) ease-out;
}

.chapter-content:hover{
    /*background-color: var(--color-hover);*/
}

@media screen and (max-width: 575px){
    .chapter >.title {
        font-size: 2em;
        margin: var(--spacing-medium) 0;
    }

    .chapter-content{
        padding: var(--spacing-tiny);
        padding-right: var(--spacing-small);
    }
}

