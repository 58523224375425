@import "./spacing.css";
@import "./layout.css";
@import "./font.css";
@import "./animations.css";
@import "./border.css";
@import url(https://fonts.googleapis.com/css?family=Exo+2:200i);

:root {
    --color-dark-blue: #0D456B;
    --color-dark-blue--lighter: #9cb1be;
    --color-blue: #0060A9;
    --color-blue--lighter: #4D90C3;
    --color-cyan: #35ABE2;
    --color-cyan--lighter: #00cdf1; /* original theme color: #72C4EB; */
    --color-cyan--very-lighter: #b6e5ee; /* original theme color: #72C4EB; */
    --color-lila: #9D599F;
    --color-lila--lighter: #BA8BBC;
    --color-purple: #93019b;
    --color-purple-dark: #540058;
    --color-dark-green: #097353;
    --color-green: #0FBD58;
    --color-green--lighter: #00e760;
    --color-error: #C81919;
    --color-wine-red: #5e2028;
    --color-error--lighter: #D95E5E;
    --color-warning: #F0BB29;
    --color-warning--lighter: #F5CF69;
    --color-pink: #F652A0;
    --color-yellow: #eeb34c;
    --color-light-yellow: #f6cc83;
    --color-dark-yellow: #928300;
    --color-dark-orange: #c75a28;
    --color-lighter-dark-orange: #e3b099;
    --color-darker-orange: #853009;
    /*--color-dark-red: #6f0000;*/
    --color-dark-red: #560319;
    --color-dark-red--lighter: 	#960018;
    --color-dark-red--darker: #560d0d;
    --color-ivory: #e1dcd0;


    --color-black: #000000;
    --color-dark: #2a2c2d;
    --color-dark--little-lighter: #2f4446;
    --color-dark--lighter: #878A8C;
    --color-grey: #C7C7C7;
    --color-grey--lighter: #EAEAEA;
    --color-light: #F4F4F4;
    --color-light--lighter: #F7F7F7;
    --color-white: #F7F7F7;


    --text-size: 101%;
    /*--text-font-family: Courier, Helvetica, Arial, sans-serif;*/
    /*--text-font-family: Consolas, monaco,down monospace;*/
    --text-font-family: 'Exo 2', sans-serif;

    /* spacing */
    --spacing-tiny: 5px;
    --spacing-small: 10px;
    --spacing-medium: 15px;
    --spacing-large: 22px;

    --spacing-timeline: 20px;

    --font-size-normal: 1em;
    --font-size-medium: 1.5em;
    --font-size-large: 1.8em;

    /* responsive width */
    --responsive-width-mobile: 600px;
    --responsive-width-extra-small: 800px;
    --responsive-width-small: 900px;
    --responsive-width-medium: 1200px;

    /* border */
    --border-radius: 0.2rem;
    --border-radius-large: 50px;
    --border-size-small: 1px;
    --border-size-medium: 2px;
    --border-size-large: 0.5rem;
}

.light {
    /* primary */
    --primary-color: var(--color-dark--little-lighter);
    --primary-color-hover: var(--color-dark--lighter);
    --primary-text-color: var(--color-dark);

    --menu-color-active: var(--color-cyan);

    /* secondary */
    --secondary-color: var(--color-dark-red);
    --secondary-color-darker: var(--color-dark-red--darker);
    --secondary-color-lighter: var(--color-dark-red--lighter);
    --secondary-text-color: var(--color-white);

    /* tertiary */
    --tertiary-color: var(--color-grey);
    --tertiary-text-color: var(--color-dark);


    --border-color: var(--secondary-color);
    /* 2x2 border */
    --2x2-border-color: var(--color-white);

    /* data */
    --data-add-color: var(--color-green);
    --data-delete-color: var(--color-error);
    --data-text-color: var(--color-black);
    --data-persistent-color: var(--color-dark--lighter);

    --color-hover: var(--color-ivory);


    /*--background-color-header: rgba(0, 0, 0, 0.5);*/
    --background-color-header: var(--primary-color);

    --background-color: var(--color-dark--lighter);
    --background-color-shadow: var(--color-black);
    --overlay-color: var(--color-ivory);
    --color-shadow-close: var(--color-white);
    --color-shadow-primary: var(--color-white);
    --color-shadow-secondary: var(--color-white);
}

.dark {
    /* primary */
    --primary-color: var(--color-cyan);
    --primary-color-hover: var(--color-cyan--lighter);
    --primary-text-color: var(--color-light);

    --menu-color-active: var(--primary-color);

    /* secondary */
    --secondary-color: var(--color-yellow);
    --secondary-color-darker: var(--color-dark-orange);
    --secondary-color-lighter: var(--color-light-yellow);

    /*--secondary-color: var(--color-blue);*/
    --secondary-text-color: var(--color-light);

    /* tertiary */
    --tertiary-color: var(--color-grey);
    --tertiary-text-color: var(--color-dark);


    --border-color: var(--primary-color);

    --text-color-h1: var(--color-white);
    --text-color-h2: var(--color-white);
    --text-color-h3: var(--color-white);
    --text-color-p: var(--color-white);

    --color-hover: rgba(0, 0, 0, .4);


    /*--background-color-header: rgba(0, 0, 0, 0.5);*/
    --background-color-header: var(--color-dark);

    --background-color: var(--color-dark--lighter);
    --background-color-shadow: var(--color-black);

    --background-color-menu : var(--color-dark);
    --background-color-menu-hover : var(--color-dark--little-lighter);
    --background-color-menu-child: var(--color-dark--lighter);
    --background-color-menu-child-hover: var(--color-grey);
    --overlay-color: rgba(0,0,0,0.9);
    --color-shadow-close: var(--color-black);
    --color-shadow-primary: var(--primary-color);
    --color-shadow-secondary: var(--secondary-color);
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;

    font-family: var(--text-font-family);
    font-size: var(--text-size);
    color: var(--primary-text-color);
    vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
    display: block;
}

body {
    line-height: 1;
}

ol, ul {
    list-style: none;
}

blockquote, q {
    quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

/* misc utils */
.no-user-select {
    user-select: none;
}