

/*@font-face {*/
/*    font-family: 'DIN OT';*/
/*    font-style: normal;*/
/*    font-weight: 900;*/
/*    src: url('./fonts/DINOT-Bold.ttf') format('truetype');*/
/*}*/

/*@font-face {*/
/*    font-family: 'DIN OT';*/
/*    font-style: normal;*/
/*    font-weight: 800;*/
/*    src: url('./fonts/DINOT-Bold.ttf') format('truetype');*/
/*}*/

/*@font-face {*/
/*    font-family: 'DIN OT';*/
/*    font-style: normal;*/
/*    font-weight: 700;*/
/*    src: url('./fonts/DINOT-Bold.ttf') format('truetype');*/
/*}*/

/*@font-face {*/
/*    font-family: 'DIN OT';*/
/*    font-style: normal;*/
/*    font-weight: 600;*/
/*    src: url('./fonts/DINOT-Medium.ttf') format('truetype');*/
/*}*/

/*@font-face {*/
/*    font-family: 'DIN OT';*/
/*    font-style: normal;*/
/*    font-weight: 500;*/
/*    src: url('./fonts/DINOT-Medium.ttf') format('truetype');*/
/*}*/

/*@font-face {*/
/*    font-family: 'DIN OT';*/
/*    font-style: normal;*/
/*    font-weight: 400;*/
/*    src: url('./fonts/DINOT-Medium.ttf') format('truetype');*/
/*}*/

/*@font-face {*/
/*    font-family: 'DIN OT';*/
/*    font-style: normal;*/
/*    font-weight: 300;*/
/*    src: url('./fonts/DINOT-Medium.ttf') format('truetype');*/
/*}*/

/*@font-face {*/
/*    font-family: 'DIN OT';*/
/*    font-style: normal;*/
/*    font-weight: 200;*/
/*    src: url('./fonts/DINOT-Light.ttf') format('truetype');*/
/*}*/

/*@font-face {*/
/*    font-family: 'DIN OT';*/
/*    font-style: normal;*/
/*    font-weight: 100;*/
/*    src: url('./fonts/DINOT-Light.ttf') format('truetype');*/
/*}*/

.font-pacifico{
    /*font-family: Courier, serif;*/
    font-family: Consolas, monaco, monospace;
    /*font-family: 'Exo 2', sans-serif;*/

    font-style: normal;
    font-variant: normal;
    font-weight: 700;
}